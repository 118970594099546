<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg">
   <g transform="translate(-370 -688)">
    <rect :fill="background" height="60" transform="translate(370 688)" width="60"/>
    <g transform="translate(379.862 708.191)">
     <path d="M244.325,323.421c-.5,0-1,0-1.5,0,0,1.609,0,3.215,0,4.822,0,.582,0,1.163.006,1.745h1.487C244.331,327.8,244.322,325.609,244.325,323.421Z" stroke="#000" stroke-width="0.038" transform="translate(-233.038 -310.387)"/>
     <path d="M457.356,88.881l-.025-.011q-.877.881-1.756,1.756h0c-.006.011-.011.028-.017.039.4.4.8.795,1.2,1.194.2.188.382.393.584.576.141-.125.274-.274.413-.407.427-.424.853-.853,1.279-1.277.031-.036.072-.061.091-.105Q458.234,89.768,457.356,88.881Z" stroke="#000" stroke-width="0.038" transform="translate(-437.21 -85.275)"/>
     <path d="M.019,323.419V324.7H1.938c0,1.764,0,3.525,0,5.29H3.417a1.5,1.5,0,0,0,.011-.249V324.7H5.347c0-.424,0-.85,0-1.277Z" stroke="#000" stroke-width="0.038" transform="translate(0 -310.387)"/>
     <path d="M457.139,3.591q.86-.856,1.717-1.714a.274.274,0,0,0,.069-.08c-.579-.565-1.144-1.141-1.717-1.709a.438.438,0,0,1-.055-.069h-.022c-.584.6-1.2,1.188-1.778,1.795C455.954,2.4,456.541,3,457.139,3.591Z" stroke="#000" stroke-width="0.038" transform="translate(-437.012 0)"/>
     <path d="M409.31,325.615a2.8,2.8,0,0,0-.845-1.335,3.608,3.608,0,0,0-1.933-.823,8.62,8.62,0,0,0-1.2-.044h-1.77a.638.638,0,0,0-.1.008c.008,2.168,0,4.337.006,6.505,0,.014-.006.039-.008.053h2.559c.23-.042.465-.039.692-.089a3.426,3.426,0,0,0,2.027-1.138,3.06,3.06,0,0,0,.678-1.573,3.871,3.871,0,0,0-.108-1.565Zm-1.687,2.18a1.789,1.789,0,0,1-.778.676,2.351,2.351,0,0,1-.922.2c-.332,0-.665,0-1,0-.017-.368,0-.737-.008-1.1,0-.961,0-1.919,0-2.88h1.027a2.082,2.082,0,0,1,1.412.554,1.909,1.909,0,0,1,.554,1.243A2.217,2.217,0,0,1,407.624,327.795Z" stroke="#000" stroke-width="0.038" transform="translate(-387.208 -310.382)"/>
     <path d="M370.267,1.816V1.794L369.043.57c-.18-.186-.371-.357-.54-.551h-.022c-.54.554-1.094,1.1-1.639,1.648a.729.729,0,0,0-.133.144q.893.889,1.783,1.781C369.085,3,369.674,2.406,370.267,1.816Z" stroke="#000" stroke-width="0.038" transform="translate(-351.934 0)"/>
     <path d="M630.761,323.862c-.058-.152-.119-.307-.188-.454q-.773-.012-1.545,0-1.321,3.178-2.642,6.353a1.166,1.166,0,0,0-.075.208H627.9c.158-.438.327-.87.485-1.307.9-.014,1.808,0,2.714-.006.047-.014.061.036.072.069.152.415.307.828.457,1.243h1.642c-.2-.521-.421-1.03-.632-1.545C632.016,326.9,631.392,325.38,630.761,323.862Zm-1.952,3.633a.368.368,0,0,1,.044-.161c.3-.789.612-1.579.911-2.368a.064.064,0,0,1,.022-.006c.313.831.631,1.659.947,2.49,0,.011,0,.033,0,.044Q629.772,327.487,628.809,327.5Z" stroke="#000" stroke-width="0.038" transform="translate(-601.09 -310.37)"/>
     <path d="M899.631,328.667v-5.245c-.5,0-1,0-1.5,0,0,2.191-.006,4.378,0,6.566h4.1v-1.315C901.368,328.669,900.5,328.672,899.631,328.667Z" stroke="#000" stroke-width="0.038" transform="translate(-861.977 -310.387)"/>
     <path d="M547.7,1.733,546.361.39c-.119-.125-.249-.238-.36-.371h-.022c-.288.3-.587.59-.881.886s-.593.6-.895.892c.014.039.05.061.078.091l1.7,1.7c.6-.584,1.185-1.191,1.786-1.775C547.762,1.78,547.726,1.758,547.7,1.733Z" stroke="#000" stroke-width="0.038" transform="translate(-522.287)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>